body {
    background: #eee;
}

header {
    margin-bottom: 16pt;
}

header > h2 {
    margin-top: 0;
}