/* ibm-plex-sans-regular - latin */
@font-face {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 400;
    src: url('../public/fonts/ibm-plex-sans-v14-latin-regular.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('../public/fonts/ibm-plex-sans-v14-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../public/fonts/ibm-plex-sans-v14-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
    url('../public/fonts/ibm-plex-sans-v14-latin-regular.woff') format('woff'), /* Modern Browsers */
    url('../public/fonts/ibm-plex-sans-v14-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../public/fonts/ibm-plex-sans-v14-latin-regular.svg#IBMPlexSans') format('svg'); /* Legacy iOS */
}

/* ibm-plex-sans-italic - latin */
@font-face {
    font-family: 'IBM Plex Sans';
    font-style: italic;
    font-weight: 400;
    src: url('../public/fonts/ibm-plex-sans-v14-latin-italic.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('../public/fonts/ibm-plex-sans-v14-latin-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../public/fonts/ibm-plex-sans-v14-latin-italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('../public/fonts/ibm-plex-sans-v14-latin-italic.woff') format('woff'), /* Modern Browsers */
    url('../public/fonts/ibm-plex-sans-v14-latin-italic.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../public/fonts/ibm-plex-sans-v14-latin-italic.svg#IBMPlexSans') format('svg'); /* Legacy iOS */
}

/* ibm-plex-sans-600 - latin */
@font-face {
    font-family: 'IBM Plex Sans';
    font-style: normal;
    font-weight: 600;
    src: url('../public/fonts/ibm-plex-sans-v14-latin-600.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('../public/fonts/ibm-plex-sans-v14-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../public/fonts/ibm-plex-sans-v14-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
    url('../public/fonts/ibm-plex-sans-v14-latin-600.woff') format('woff'), /* Modern Browsers */
    url('../public/fonts/ibm-plex-sans-v14-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../public/fonts/ibm-plex-sans-v14-latin-600.svg#IBMPlexSans') format('svg'); /* Legacy iOS */
}

/* ibm-plex-sans-600italic - latin */
@font-face {
    font-family: 'IBM Plex Sans';
    font-style: italic;
    font-weight: 600;
    src: url('../public/fonts/ibm-plex-sans-v14-latin-600italic.eot'); /* IE9 Compat Modes */
    src: local(''),
    url('../public/fonts/ibm-plex-sans-v14-latin-600italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../public/fonts/ibm-plex-sans-v14-latin-600italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('../public/fonts/ibm-plex-sans-v14-latin-600italic.woff') format('woff'), /* Modern Browsers */
    url('../public/fonts/ibm-plex-sans-v14-latin-600italic.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../public/fonts/ibm-plex-sans-v14-latin-600italic.svg#IBMPlexSans') format('svg'); /* Legacy iOS */
}


:not(input) {
    user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -webkit-user-select: none;
}

.MuiMenuItem-root.Mui-selected {
    font-weight: 600;
}